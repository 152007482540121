import { Button, Image, Typography, Spin, notification } from "antd";
import CategoryCard from "./CategoryCard";
import TopSellingProductCard from "./TopSellingProductCard";
import { Images } from "../../images";
import React, { useEffect } from "react";
import { actions } from "../../contexts/category/actions";
import { useCategoryDispatch, useCategoryState } from "../../contexts/category";
import useDebounce from "../UseDebounce";
import { useNavigate } from "react-router-dom";
import routes from "../../helpers/routes";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { Fade } from "react-awesome-reveal";
import HelmetComponent from "../Helmet/HelmetComponent";
import { SEO } from "../../helpers/seoConstant";
import { BANNER } from "../../helpers/constants";
import { bannerArrow } from "../../images/SVGComponents";


const MarketPlace = ({ user, isAuthenticated }) => {
  const limit = 10, offset = 0;
  const navigate = useNavigate();
  const dispatch = useCategoryDispatch();
  const debouncedSearchTerm = useDebounce("", 1000);
  const { iscategorysLoading } = useCategoryState();

  useEffect(() => {
    if (isAuthenticated) {
      const loginCount = localStorage.getItem('loginCount');
      // If loginCount is not set, it means this is the first login
      if (!loginCount) {
        // Show the notification
        notification.open({
          description: 'Click here to review some updates on your Assets and Orders!',
          icon: null,
          btn: (
            <Button
              type="primary"
              onClick={() => navigateToUserProfile()}
              style={{
                borderRadius: '20px',
                color: '#fff',
              }}
            >
              Explore now
            </Button>
          ),
          placement: 'bottomRight',
          style: {
            borderRadius: '12px',
          },
        });
        // Set loginCount to 1 to indicate the user has logged in at least once
        localStorage.setItem('loginCount', '1');
      }
    }
  }, [isAuthenticated, navigate]);

  const navigateToUserProfile = () => {
    navigate(`${routes.MarketplaceUserProfile.url.replace(":commonName", user.commonName)}?tab=my-activity`);
  };

  useEffect(() => {
    actions.fetchCategories(dispatch, limit, offset, debouncedSearchTerm);
  }, [dispatch, limit, offset, debouncedSearchTerm]);

  const linkUrl = window.location.href;
  const navRoute = routes.MarketplaceCategoryProductList.url.replace(':category', 'All');

  const ButtonElement = ({ desktopText, mobileText }) => <div className="relative flex top-[156px] sm:top-[250px] xl:top-[65%] 3xl:top-[70%] left-[4%] sm:left-[7.5%] md:left-[7%] md:top-60 z-50">
    <Button
      id="viewMore"
      onClick={() => {
        navigate(navRoute);
        sessionStorage.setItem('scrollPosition', 0);
      }}
      className="gradient-button border-0 h-auto md:h-11 border-primary bg-white text-primary hover:text-white"
    >
      <div className="flex items-center">
        <div className="hidden sm:block font-semibold text-lg">
          {desktopText}
        </div>
        <div className="sm:hidden font-semibold text-base">
          {mobileText}
        </div>
        <span className="ml-1">{bannerArrow}</span>
      </div>
    </Button>
  </div>

  const CarouselElement = ({ scrollT }) =>
    <Carousel autoPlay centerSlidePercentage={95} showArrows={false} infiniteLoop showStatus={false} swipeable emulateTouch autoFocus centerMode
      preventMovementUntilSwipeScrollTolerance={true} swipeScrollTolerance={scrollT}
    >
      {BANNER.map((item, index) => (
        <div key={index} className="no-select relative p-2 h-[222px] sm:h-[380px] 3xl:h-[480px] mx-1 md:mx-2 md:mt-6 lg:mx-3">
          <ButtonElement desktopText={item.desktopText} mobileText={item.mobileText} />
            {item.text}
          <div className="sm:hidden">
            <img
              alt={item.alt}
              title={item.title}
              className="no-select absolute inset-0 z-10 h-[222px] md:w-[90%] rounded-md md:rounded-[14px] drop-shadow-md"
              height={380}
              width="100%"
              src={item.mobileImg}
              preview={false}
            />
          </div>
          <div className="hidden sm:block md:hidden">
            <img
              alt={item.alt}
              title={item.title}
              className="no-select absolute inset-0 z-10 h-[380px] md:w-[90%] rounded-md md:rounded-[14px] drop-shadow-md"
              height={380}
              width="100%"
              src={item.tabletImg}
              preview={false}
            />
          </div>
          <div className="hidden md:block lg:hidden">
            <img
              alt={item.alt}
              title={item.title}
              className="no-select absolute inset-0 z-10 md:h-[330px] md:w-[90%] rounded-md md:rounded-[14px] drop-shadow-md"
              height={380}
              width="100%"
              src={item.laptopImg}
              preview={false}
            />
          </div>
          <div className="hidden lg:block">
            <img
              alt={item.alt}
              title={item.title}
              className="no-select absolute inset-0 z-10 lg:h-[330px] 3xl:h-[480px] md:w-[90%] rounded-md md:rounded-[14px] drop-shadow-md"
              height={380}
              width="100%"
              src={item.desktopImg}
              preview={false}
            />
          </div>
        </div>
      ))}
    </Carousel>

  return (
    <>
      <HelmetComponent
        title={SEO.TITLE_META}
        description={SEO.DESCRIPTION_META}
        link={linkUrl} />
      <Fade triggerOnce>
        <div className="hidden md:block">
          <CarouselElement scrollT={130} />
        </div>
        <div className="md:hidden">
          <CarouselElement scrollT={50} />
        </div>
      </Fade>
      {iscategorysLoading ? (
        <div className="h-96 flex justify-center items-center">
          <Spin spinning={iscategorysLoading} size="large" />
        </div>
      ) : (
        <div className="px-3 md:px-0 py-30 mt-6 md:mt-10 mb-10">
          {/* <CategoryCard /> */}
          <TopSellingProductCard />
        </div>
      )}
    </>
  );
};

export default MarketPlace;
